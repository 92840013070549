@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: normal;
  src: local('ProximaNova'), url('../fonts/ProximaNova-Regular.otf') format('opentype');
}


@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 700;
  src: local('ProximaNova'), url('../fonts/ProximaNova-Bold.otf') format('opentype');
}
  